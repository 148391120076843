.quickbuy {
  &-modal--root {
    .modal-body {
      padding: 12px;

      @include min-md {
        padding: 24px;
      }
    }

    .modal-header .btn-close {
      margin-right: 0;
    }

    .modal-content {
      background-image: url('../../images/bg-wl.jpg');
      // background-image: url('../../short-terms/whitelist--mobile.png');
      background-position: center center;
      background-size: cover; // 240%;
      background-repeat: no-repeat;
      width: 100%;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;

      @include min-md {
        width: 90%;
      }

      .quickbuy-body {
        @include posr(2);
        backdrop-filter: blur(1px);
      }
    }

    .body--bottom-gradient {
      @include posa(1);
      @include size(100% 80%);
      width: calc(100% + 1px);
      left: 0;
      background: rgb(12, 24, 57);
      background: linear-gradient(
        180deg,
        rgba(12, 24, 57, 0) 0%,
        rgba(12, 24, 57, 0) 5%,
        rgba(12, 24, 57, 0.908000700280112) 65%,
        rgba(12, 24, 57, 1) 77%,
        rgba(12, 24, 57, 1) 100%
      );
      bottom: 0;
    }
  }

  &--element {
    @include posr(4);
  }

  &--element-no-permission-label {
    font-weight: 600;
    line-height: 1.45;
  }

  &--account-info {
    @include posr(5);
    background-color: $color24;
    border-radius: 24px;
    padding: 14px 30px;
    margin-bottom: 22px;
  }

  &-package {
    &--packages-wrapper {
      @extend %posr;
      display: flex;
      flex-flow: row wrap;
      row-gap: 12px;
    }

    &--heading {
      margin: 16px 0 22px;
    }
  }
}

.quickbuy-number {
  &-input--wrapper {
    @include flexRow;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
  }

  &--input-value {
    @extend .font2;
    @include fz(62);
    color: $color01;
    font-weight: 600;
    text-align: center;
    width: 100%;
    line-height: 1;
    height: 46px;
    background-color: transparent;
    border: none;
    outline: none;
  }

  &--value {
    min-width: 60px;
    margin-left: 16px;
    margin-right: 16px;
  }

  &--change-button {
    $size: 36px;
    @include size($size);
    @include fz(24);
    @extend .font2;
    font-weight: 700;
    border-radius: 50%;
    line-height: $size;
    padding: 0;
    min-height: $size;
  }
}

.quickbuy-number-total {
  @include flexRow;
  align-items: center;
  justify-content: center;

  &--label,
  &--value {
    line-height: 1;
  }

  &--label {
    margin-right: 10px;

    img {
      @include size(28px);
      display: block;
      max-width: 100%;
    }
  }

  &--value {
    @include fz(36);
    font-weight: 600;
  }
}

.quickbuy--account {
  &-info {
    @include flexRow;
    align-items: center;

    .icon-wallet {
      @include fz(24);
      display: inline-block;
      width: 32px;
    }
  }

  &-item {
    @include flexRow;
    align-items: center;

    .icon {
      margin-right: 8px;
      margin-top: 2px;
    }

    .value {
      @extend .font2;
    }

    &.balance {
      margin-left: auto;

      .icon {
        width: 18px;
      }
    }

    &.wallet {
      .value {
        width: 96px;
      }
    }
  }
}

.btn--buy-labled-egg,
.btn--claim-whitelist,
.btn--start-minting {
  @extend .font2;
  @include fz(26);
  box-shadow: 0px 4px 39px -1px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  border-radius: 24px;

  & > span {
    @include posr;
    top: 2px;
  }

  &:hover {
    color: $color01;
  }
}

.btn--claim-whitelist {
  @include fz(23);
  padding: 16px 32px;
}

.btn--buy-labled-egg {
  @include fz(28);
  padding: 16px 86px;
  border-radius: 32px;
}

.btn--start-minting {
  margin-top: 64px;

  &.counter {
    margin-top: 34px;
    min-width: 65%;
  }
}

.purchase-counter--wrapper {
  padding-top: 30px;

  .egg-remaining {
    @include fz(14);
    text-align: center;
  }
}

.buyer--detail {
  margin-bottom: 24px;
}

.quickbuy-package--purchase-status {
  .body {
    .helper {
      margin-bottom: 16px;
    }
  }
}

.quickbuy-package {
  &--heading {
    .claim-progress--wrapper {
      padding: 30px 0;
    }
  }
}

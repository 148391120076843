body.screen--worldmap {
  overflow: hidden;
}

.header--worldmap {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 500;

  .header--worldmap-logo {
    @extend %animate;
    @include size(120px 100px);
    display: block;
    transform: translateY(0) scale(1);
    background-image: url($logoPrimary);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -9999px;
    margin: 0 auto;
    padding: 0;

    @include min-md {
      @include size(200px 100px);
      transform: translateY(7px) scale(1.15);
    }
  }
}

.eggForce-worldmap--root {
  background-color: $modalBackground;

  .leaflet-control-container {
    display: block;
  }

  .eggForce--worldmap-top-gradient {
    background: linear-gradient(
      180deg,
      rgba($modalBackground, 1) 0%,
      rgba($modalBackground, 1) 6.5%,
      rgba($modalBackground, 0) 60%,
      rgba($modalBackground, 0) 100%
    );
    height: 100px;
    position: fixed;
    width: 100%;
    z-index: 400;
    top: 0;
  }

  .eggForce--worldmap-bottom-gradient {
    background: linear-gradient(
      0deg,
      rgba($modalBackground, 1) 0%,
      rgba($modalBackground, 1) 6.5%,
      rgba($modalBackground, 0) 60%,
      rgba($modalBackground, 0) 100%
    );
    height: 100px;
    position: fixed;
    width: 100%;
    z-index: 400;
    bottom: 0;
  }

  .eggForce--worldmap-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($color03, 0.6);
    left: 0;
    top: 0;
    z-index: 500;
  }
}

.leaflet-container .leaflet-marker-pane img {
  padding: 6px;
}

.world--validator-marker {
  .leaflet-popup-content-wrapper {
    background-color: transparent;
    border-radius: 24px;
    padding: 0;
  }

  .leaflet-popup-content {
    margin: 0;
  }
}

/**
* Card validator
*/
.card-validator {
  &--root {
    border-radius: 24px;
    box-shadow: -1px 15px 30px -12px black;
    max-width: 310px;
    width: 280px;
  }

  &--image {
    background-color: $color03;
    border-radius: 24px 24px 0 0;

    .img {
      border-radius: 24px 24px 0 0;
    }
  }

  &--background {
    @extend %posr;
    height: 220px;
    overflow: hidden;
    border-radius: 24px 24px 0 0;

    &:after {
      @extend %posa;
      @include size(100%);
      background: linear-gradient(
        0deg,
        rgba($color03, 1) 0%,
        rgba($color03, 1) 6.5%,
        rgba($color03, 0) 60%,
        rgba($color03, 0) 100%
      );
      bottom: 0;
      content: '';
      left: 0;
    }

    &:hover {
      .img {
        transform: scale(1.15);
      }
    }

    .img {
      @include size(100%);
      @extend %animate;
      display: block;
      max-width: 100%;
      object-fit: cover;
      object-position: center bottom;
      transition-duration: 8s;
    }
  }

  &--body {
    @include fz(13);
    background-color: $color01;
    color: #595959;
    padding: 1.5rem 0.9rem;

    .address {
      margin-bottom: 0.75rem;
      max-width: 120px;
      opacity: 0.75;
      margin-left: 33px;
      margin-top: -5px;
    }

    .meta {
      overflow: auto;
      max-height: 160px;
    }

    .name-wrapper {
      @extend %posr;
      @extend .font2;
      @include fz(22);
      align-items: flex-end;
      display: inline-flex;
      flex-flow: row nowrap;
      margin-left: auto;
      margin-right: auto;
      color: $color02;
      font-weight: 700;
      line-height: 1;

      &.verified {
        &:before {
          @include posa;
          @include size(16px);
          background-color: $color09;
          border-radius: 50%;
          content: '';
          right: -24px;
          top: 5px;
        }
        &:after {
          @include posa;
          @include size(16px);
          @include fz(12);
          color: $color01;
          content: '\2713';
          right: -25px;
          top: 10px;
        }
      }

      .logo {
        height: 28px;
        margin-right: 6px;

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  &--hatch-button {
    margin-top: 8px;
    padding-left: 6px;
    padding-right: 6px;
  }

  &--info {
    @include posr;
    background-color: $color03;
    border-radius: 0 0 24px 24px;
    padding: 16px 0 8px;

    .btn--stake-from-validator {
      min-height: 36px;
      width: 100%;

      &.posa {
        @include posa;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
      }
    }

    &-row {
      @include flexRow;
      text-align: center;
    }

    &-column {
      flex: 0 0 auto;

      @at-root .has-2columns & {
        &.column-1,
        &.column-2 {
          width: 50%;
        }
      }

      @at-root .has-3columns & {
        &.column-1 {
          width: 44%;
        }

        &.column-2,
        &.column-3 {
          width: 28%;
        }
      }
    }
  }
}

.validator-item {
  &--stats {
    @extend .font2;
    font-weight: 600;
    color: $color01;

    &.xp-boost {
      color: $color07;
    }

    .value {
      @extend %posr;
      @include fz(20);
      font-weight: 700;
      line-height: 1;
      display: inline-block;

      .symbol {
        @extend %posa;
        @include fz(12);
        left: calc(100% + 2px);
        top: 0;
      }
    }

    .label {
      @include fz(14);
      transform: translateY(0);
      font-weight: 400;
    }
  }
}

.validator-marker {
  @extend %animate;
  background: linear-gradient(126.64deg, $color01 12.35%, #c8c8c8 93.23%);
  border-radius: 50%;
  border: 4px solid rgba($color01, 0.5);
  backdrop-filter: blur(10px);

  &:hover:not(.ignore-shadow),
  &:active:not(.ignore-shadow) {
    border-color: $color06;
    box-shadow: $bs03;
  }

  &.internal {
    backdrop-filter: blur(0);
    border: none;
    background: transparent;
    border-radius: 0;
    padding: 2px !important;
  }
}

.marker {
  &--share {
    @extend %animate;
    @extend .font2;
    @include textShadow02();
    cursor: default;
    font-weight: 600;
    font-size: 1.85rem;
    display: block;
    color: $color01;
    padding-bottom: 10px;
    padding-top: 10px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &--land-name {
    @extend %animate;
    @include fz(20);
    transition-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    min-width: 220px;
    opacity: 0.85;
    z-index: 0 !important; // Make sure land name is always below Validator marker
    transition-property: font-size, top;

    &.zoom-3 {
      @include fz(21);
    }

    &.zoom-5 {
      @include fz(24);
    }
  }

  &--tooltip {
    &.leaflet-tooltip {
      @include fz(26);
      @include textShadow01;
      background-color: transparent;
      border: none;
      text-align: center;
      box-shadow: none;
      padding-bottom: 0;
      padding-top: 0;

      &:before {
        display: none;
      }
    }
  }
}

.mobile--notification,
.social--sharing--bottom {
  @include fz(13);
  background: $modalBackground;
  color: $color01;
  text-align: center;
  padding: 10px 12px;
  width: 100%;
  min-width: 280px;
  font-weight: 600;
  border-radius: 12px;
}

.social--sharing--bottom {
  background-color: transparent;
}

.btn--enter-callista-world {
  @extend %animate;
  @extend %posr;
  @extend .btn--style-3;
  @include onHoldAndScaleDown;
  @include textShadow03;
  @include fz(14);
  background-image: url('../../images/hero-image--mobile.webp');
  background-position: center 14%;
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  box-shadow: 0px 0px 25px -1px $color04;
  box-shadow: $bs01;
  min-height: auto;
  overflow: hidden;
  padding: 8px 12px;

  transition-property: box-shadow, transform, color;
  will-change: box-shadow, transform, color;

  &.transparent {
    background-image: none;
  }

  &.use-map-background {
    background-image: url('../../images/img--mock-world.png');
  }

  &:before {
    @include size(100% 100%);
    @include posa(-1);
    bottom: 0;
    left: 0;
    content: '';
    background: rgb(7, 26, 82);
    background: linear-gradient(
      -90deg,
      rgba(7, 26, 82, 0) 0%,
      rgba(7, 26, 82, 1) 65%,
      rgba(7, 26, 82, 1) 100%
    );
  }

  &:hover {
    background-color: $modalBackground;
    border-color: $color03;
    color: $color01;
    box-shadow: $bs03;
  }

  .icon {
    width: 36px;
    margin-right: 10px;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &.rounded {
    border-radius: 50% !important;
    padding: 10px;

    .icon {
      width: 56px;
      margin-right: 0;
    }

    .label {
      display: none;
      visibility: hidden;
    }
  }

  &--sticky {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 10;
  }

  @include query-mobile {
    width: 100%;
  }
}

.btn--enter-discord-community {
  @extend %animate;
  @include onHoldAndScaleDown;
  @include textShadow03;
  @include fz(14);
  color: $color10;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;

  .sharing-button {
    .label {
      .text-2 {
        display: inline-block;
        margin-top: 4px;
      }
    }
  }
}
